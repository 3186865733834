import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Stack from 'react-bootstrap/Stack'

import { StaticImage } from "gatsby-plugin-image"

import './footer.scss';

const Footer = () => (
  <footer>
    <Container>
      <Row>
        <Col>
          <Stack direction="horizontal" gap={3}>
            <p>
              INTELLIVA, LLC<br />
              131 NE 1st Avenue, Suite 100 | Boca Raton, FL 33432<br />
              1-561-362-8888 | <a href="mailto:hello@intelliva.com?subject=Intelliva Inquiry">HELLO@INTELLIVA.COM</a>
            </p>
            <StaticImage 
              src="../../images/intelliva-logo.png" 
              alt="Intelliva" 
              title="Intelliva"
              width="147"
              height="61" 
              className="logoFtr ms-auto" 
              loading="lazy" 
            />
          </Stack>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
