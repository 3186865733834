import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import './header.scss';

import LPForm from "../LPForm"

const HeaderHome = () => (
  <header className="home">
    <Container>
      <Row>
        <Col md={8}>
          <StaticImage 
            src="../../images/intelliva-logo-w.png" 
            alt="Intelliva" 
            title="Intelliva"
            width="250"
            height="103" 
            className="logoHdr" 
            loading="lazy" 
          />
          <h1>Welcome<br />to Intelliva</h1>
          <p className="lead">The Services Industry Productivity Cloud for People, Projects &amp; Profits</p>
        </Col>
        <Col md={4}>
          <LPForm />
        </Col>
      </Row>
      <Row className="align-items-center imageCopy">
        <Col md={5}>
          <StaticImage 
            src="../../images/ui-screen-1.png" 
            alt="UI SCreen 2" 
            title="UI SCreen 2"
            width="487"
            height="281" 
            className="screenOne" 
            loading="lazy" 
          />
        </Col>
        <Col md={7}>
          <p className="introCopy">
            Intelliva is a modern, secure, purpose-built cloud platform for managing people, projects, productivity, and profitability. Raise your level of client service by using Intelliva in your workplace to help your organization better communicate, collaborate, market, measure, and manage business activity from anywhere, at any time.
          </p>
        </Col>
      </Row>
    </Container>
  </header>
)

export default HeaderHome
