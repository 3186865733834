import React, { Component } from 'react'

import $ from 'jquery'
import Cookies from 'js-cookie'

import FormField from '../FormField';

import './lpform.scss';

class LPForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: '',
            contactCompanySize: '',
            contactIndustry: '',
            contactHQCountry: ''
        };
  
        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
        this.companySizeChange = this.companySizeChange.bind(this);
        this.industryChange = this.industryChange.bind(this);
        this.hqCountryChange = this.hqCountryChange.bind(this);
    }
  
    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();
  
        // console.log(post_data);
  
        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: '',
            contactCompanySize: '',
            contactIndustry: '',
            contactHQCountry: ''
        });
  
        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://intelliva.cc";
  
                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;
  
                $('#heroForm').hide();
                $('.lpForm .thankyou').show();
                // window.open(
                //     'https://mm-stats.com/gcd84',
                //     '_blank' // <- This is what makes it open in a new tab.
                // );
            },
            // Fail..
            error: function(result) {
                let r = result[0];
  
                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }
  
    nameChange(e){
        this.setState({contactName: e.target.value})
    }
  
    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }
  
    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }
  
    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }
  
    companySizeChange(e){
      this.setState({contactCompanySize: e.target.value})
    }
  
    industryChange(e){
      this.setState({contactIndustry: e.target.value})
    }
  
    hqCountryChange(e){
      this.setState({contactHQCountry: e.target.value})
    }
  
    render() {
        return (
            <div className="lpForm">
                <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                    <h2>Schedule Your Demo Today!</h2>
                    <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Name"}/>
                    <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Work Email"}/>
                    <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"phone"} label={"Phone"}/>
                    <FormField fieldID={"company"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                    <FormField fieldID={"companySize"}  valChange={this.companySizeChange} val={this.state.contactCompanySize} type={"textnr"}  name={"companySize"} label={"Company Size"}/>
                    <FormField fieldID={"industry"}  valChange={this.industryChange} val={this.state.contactIndustry} type={"textnr"}  name={"industry"} label={"Industry"}/>
                    <FormField fieldID={"hqCountry"}  valChange={this.hqCountryChange} val={this.state.contactHQCountry} type={"selectcountry"}  name={"hqCountry"} label={"HQ Country"}/>
                    <button type="submit" className="btn btn-white">SUBMIT</button>
                    <p>By clicking Submit, you agree to the Terms of Service and Privacy Policy.</p>
                    <span className="form-group">
                        <input type="hidden" name="referrer" value="https://www.intelliva.com/" />
                        <input type="hidden" name="auth" value="8e176de0-5bb1-470b-a39c-82df61ce649c" />
                        <input type="text" className="d-none hu" id="human-check" name="human-check" />
                        <div className="feedback"></div>
                        <div className="fix"></div>
                    </span>
                </form>
                <div className="thankyou">
                    <h4>Thank You</h4>
                    <p>Your submission has been sent.</p>
                    <p>Add info@intelliva.com to your contacts to ensure future communications will not be sent to spam.</p>
                </div>
            </div>
        )
    }
}

export default LPForm
